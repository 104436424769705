<template>
  <div
    id="controller"
    :class="
      `header-fixed header-mobile-fixed ${
        $route.meta.sidebar ? 'sidebar-enabled' : ''
      }`
    "
  >
    <div class="d-flex flex-column flex-root" v-if="areTranslationsLoaded">
      <div class="d-flex flex-row flex-column-fluid page">
        <Menu />
        <router-view />
        <b-sidebar
          id="sidebar-right"
          title="Sidebar"
          right
          shadow
          v-if="$route.meta.sidebar"
        >
          <div class="px-3 py-2"></div>
        </b-sidebar>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Menu from "@/components/controller/Menu.component";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Controller",
  components: {
    Menu
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("Controller", [
      "loadCompanySelected",
      "loadControllerCompanies"
    ])
  },
  computed: {
    ...mapGetters("Constants", ["isConstantsActive"]),
    ...mapGetters("Controller", [
      "companies",
      "companySelected",
      "isCompanySelectedLoaded",
      "areControllerCompaniesLoaded"
    ]),
    ...mapGetters("Translations", ["areTranslationsLoaded"])
  },
  watch: {
    isConstantsActive(value) {
      if (value === true && !this.areControllerCompaniesLoaded) {
        this.loadControllerCompanies();
      }
    },
    isCompanySelectedLoaded(value) {
      if (localStorage.getItem("managedCompanyId") && value === true) {
        this.$router.push({ name: "ControllerDashboard" });
      }
    },
    areControllerCompaniesLoaded(value) {
      if (value === true) {
        if (!this.companySelected && this.companies.length > 1) {
          this.$router.push({ name: "ControllerCompaniesManagement" });
        }
        if (!this.companySelected && this.companies.length <= 1) {
          localStorage.setItem(
            "managedCompanyId",
            this.companies[0].stakeholderId
          );
          localStorage.setItem(
            "companySelected",
            JSON.stringify(this.companies[0])
          );
          store.dispatch("Controller/setCompany", this.companies[0]);
          this.$router.push({ name: "ControllerDashboard" });
        }
      }
    }
  },
  mounted() {
    if (this.isConstantsActive && !this.areControllerCompaniesLoaded) {
      this.loadControllerCompanies();
    }

    if (this.areControllerCompaniesLoaded && this.companies.length > 1) {
      this.$router.push({ name: "ControllerCompaniesManagement" });
    }

    if (this.areControllerCompaniesLoaded && this.companies.length <= 1) {
      localStorage.setItem("managedCompanyId", this.companies[0].stakeholderId);
      localStorage.setItem(
        "companySelected",
        JSON.stringify(this.companies[0])
      );
      store.dispatch("Controller/setCompany", this.companies[0]);
      this.$router.push({ name: "ControllerDashboard" });
    }
  }
};
</script>

<style lang="scss" src="@/assets/scss/controller/style.scss"></style>
<style src="@/assets/scss/login.scss" lang="scss" scoped></style>

<style lang="scss">
.footer {
  margin-top: auto;
  width: 100%;
  display: table-row;
}
</style>
