<template>
  <div
    id="kt_aside"
    class="aside aside-left d-flex flex-column justify-content-between"
  >
    <!--begin::Brand-->
    <div v-if="companySelected">
      <div
        class="aside-brand d-flex flex-column align-items-center flex-column-auto pb-5"
      >
        <!--begin::Logo-->
        <div class="btn p-0 symbol symbol-85">
          <div class="symbol-label bg-transparent pt-4">
            <b-img
              style="filter: invert(100%)"
              :src="require('@/assets/images/menu/simplelogo.png')"
              class="max-h-80px max-w-80px"
            >
            </b-img>
          </div>
        </div>
        <!--end::Logo-->
      </div>
      <!--end::Brand-->
      <!--begin::Nav Wrapper-->
      <div
        class="aside-nav d-flex flex-column align-items-center flex-column-fluid pb-10 scroll ps ps--active-y"
        style="overflow: hidden;"
      >
        <!--begin::Nav-->
        <ul class="nav flex-column">
          <!--begin::Item-->
          <li
            v-for="element in visibleMenu"
            :key="element.icon"
            class="nav-item mb-2"
            v-b-tooltip.hover.right="element.title"
          >
            <router-link
              :to="element.to"
              class="nav-link btn btn-icon btn-lg"
              active-class="btn-light-secondary"
            >
              <span class="svg-icon svg-icon-xxl svg-icon-dark-50">
                <!--begin::Svg Icon | path:/metronic/theme/html/demo6/dist/assets/media/svg/icons/Layout/Layout-4-blocks.svg-->
                <simple-svg
                  :src="require(`@/assets/images/icons/${element.icon}`)"
                />
                <!--end::Svg Icon-->
              </span>
            </router-link>
          </li>
          <!--end::Item-->
        </ul>
        <!--end::Nav-->
        <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
          <div
            class="ps__thumb-x"
            tabindex="0"
            style="left: 0px; width: 0px;"
          ></div>
        </div>
        <div class="ps__rail-y" style="top: 0px; right: 0px; height: 296px;">
          <div
            class="ps__thumb-y"
            tabindex="0"
            style="top: 0px; height: 256px;"
          ></div>
        </div>
      </div>
    </div>
    <!--end::Nav Wrapper-->
    <!--begin::Footer-->
    <div
      class="aside-footer d-flex flex-column align-items-center flex-column-auto py-8"
    >
      <div
        class="aside-brand d-flex flex-column align-items-center flex-column-auto pt-5 pt-lg-4 pb-10"
      >
        <!--begin::Logo-->
        <div
          class="btn p-0 symbol symbol-60 symbol-light"
          @click="panel = !panel"
        >
          <div class="symbol-label">
            <avatar size="xs" :avatar="avatar" class="align-self-end" />
          </div>
        </div>
        <!--end::Logo-->
      </div>
      <profiles profile="controller"></profiles>
      <!--begin::Languages-->
      <div id="ddlanguages">
        <b-dropdown
          class="dropdown topbar-item"
          toggle-class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1"
          menu-class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right"
          offset="10"
          right
          :no-caret="true"
        >
          <template slot="button-content">
            <b-button
              tag="div"
              class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg"
            >
              <img
                class="h-20px w-20px rounded-sm"
                :src="require(`@/assets/images/flags/${lang}.svg`)"
                alt=""
              />
            </b-button>
          </template>
          <b-dropdown-item
            v-for="lang in languages"
            :key="lang"
            @click="changeLanguage(lang)"
          >
            <div class="d-flex align-items-center">
              <span class="symbol symbol-20 mr-3">
                <img
                  :src="require(`@/assets/images/flags/${lang}.svg`)"
                  alt=""
                />
              </span>
              <span class="navi-text" style="white-space: nowrap">
                {{ $t(`generic.languages.${lang}`) }}
              </span>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <!--end::Languages-->
    </div>
    <!--end::Footer-->
    <transition
      enter-active-class="animate__animated animate__slideInLeft"
      leave-active-class="animate__animated animate__slideOutLeft"
    >
      <user-panel
        v-show="panel"
        @user-panel-closed="panel = false"
        side="left"
        to="Controller"
      />
    </transition>
    <div class="offcanvas-overlay" @click="panel = false" v-if="panel"></div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";

import UserPanel from "@/components/menu/UserPanel.component";
import Profiles from "@/components/menu/Profiles.component";
import Avatar from "@/components/profile/Avatar.component";
import { SimpleSVG } from "vue-simple-svg";

export default {
  name: "Menu",
  components: {
    UserPanel,
    Profiles,
    "simple-svg": SimpleSVG,
    Avatar
  },
  data() {
    return {
      panel: false,
      mobilePanel: false,
      mobileMenu: false,
      profileType: localStorage.getItem("profiles")
    };
  },
  computed: {
    ...mapGetters("User", ["fullname", "avatar", "company", "isUserLoaded"]),
    ...mapGetters("Constants", ["isConstantsActive", "getConstant"]),
    ...mapGetters("Auth", ["lang", "hasModule"]),
    ...mapGetters("Controller", [
      "companySelected",
      "companies",
      "areControllerCompaniesLoaded"
    ]),
    languages() {
      return _.intersection(
        Object.keys(this.$i18n.messages),
        JSON.parse(localStorage.getItem("languages"))
      );
    },
    visibleMenu() {
      return _.filter(this.menu, item => {
        return item.service === undefined || this.hasModule([item.service]);
      });
    },
    menu() {
      let menu = [
        {
          title: this.$t("menu.controller.dashboard.title"),
          subtitle: this.$t("menu.controller.dashboard.subtitle"),
          icon: "layout-grid.svg",
          to: { name: "ControllerDashboard" }
        },
        {
          title: this.$t("menu.controller.users.title"),
          subtitle: this.$t("menu.controller.users.subtitle"),
          icon: "group.svg",
          to: { name: "Users" }
        },
        {
          title: this.$t("menu.controller.departments.title"),
          subtitle: this.$t("menu.controller.departments.subtitle"),
          icon: "building.svg",
          to: { name: "Departments" }
        },
        {
          title: this.$t("menu.controller.orgchart.title"),
          subtitle: this.$t("menu.controller.orgchart.subtitle"),
          icon: "bezier-curve.svg",
          to: { name: "Organization" }
        },
        {
          title: this.$t("menu.controller.actionPlan.title"),
          subtitle: this.$t("menu.controller.actionPlan.subtitle"),
          to: { name: "ActionPlan" },
          icon: "map-trip.svg",
          service: "risk"
        },
        {
          title: this.$t("menu.controller.phishing.title"),
          subtitle: this.$t("menu.controller.phishing.subtitle"),
          icon: "phishing.svg",
          to: { name: "ListCampaigns" },
          service: "phishing"
        }
      ];

      if (this.companies.length > 1) {
        menu.unshift({
          title: this.$t("admin.menu.companiesManagement.title"),
          subtitle: this.$t("admin.menu.companiesManagement.subtitle"),
          icon: "change.svg",
          to: { name: "ControllerCompaniesManagement" }
        });
      }

      return menu;
    }
  },
  methods: {
    ...mapActions("Auth", ["changeLanguage", "profiles"]),
    ...mapActions("User", ["loadUser"])
  },
  watch: {
    $route: function() {
      this.mobilePanel = false;
      this.mobileMenu = false;
      this.panel = false;
    },
    isConstantsActive: function(value) {
      if (value) {
        this.loadUser();
      }
    },
    isUserLoaded: function(value) {
      if (!value) {
        this.loadUser();
      }
    }
  },
  mounted() {
    if (this.isConstantsActive && !this.isUserLoaded) {
      this.loadUser();
    }
  }
};
</script>

<style lang="scss" scoped>
.svg-icon > svg:focus {
  outline-width: 0;
}
</style>
<style lang="scss">
.dropdown-menu.menu-rounded {
  border-radius: 30px;
}
.company-white-logo {
  filter: white; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}
.company-logo {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}

ul.dropdown-menu.dropdown-menu.p-0.m-0.dropdown-menu-anim-up.dropdown-menu-sm.dropdown-menu-right.dropdown-menu-right.show {
  width: max-content;
}
</style>
